(function ($) {
  var content_swap = function (offer_key) {
    // offer_promo
    var $promo = $('script.inline-template[path="clinique_content_swap/offer_promo"][data-offerkey="' + offer_key + '"]');

    if ($promo.length !== 0) {
      var promo_html = $.parseHTML($promo.html());
      var content = $(promo_html).find('.offer-promo-v1')[0];

      $('.offer-promo-v1[data-offerkey="' + offer_key + '"]').html(content);
    } else {
      $('.offer-promo-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // gnav_offer
    var $gnav_offer = $('script.inline-template[path="clinique_content_swap/gnav_offer"][data-offerkey="' + offer_key + '"]');

    if ($gnav_offer.length !== 0) {
      var gnav_offer_html = $.parseHTML($gnav_offer.html());
      var content = $(gnav_offer_html).find('.gnav-offer-v1')[0];
      var target = $('.gnav-offer-v1[data-offerkey="' + offer_key + '"]');

      target.attr('style', $(content).attr('style'));
      target.attr('data-offerkey', $(content).attr('data-offerkey'));
      if (content) {
        target.html(content.innerHTML);
      }
      Drupal.behaviors.gnavOfferV1.attach();
    } else {
      $('.gnav-offer-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // tout_image
    var $tout_image = $('script.inline-template[path="clinique_content_swap/tout_image"][data-offerkey="' + offer_key + '"]');

    if ($tout_image.length !== 0) {
      var tout_image_html = $.parseHTML($tout_image.html());

      $('.tout-image-v1[data-offerkey="' + offer_key + '"]').html(tout_image_html);
    } else {
      $('.tout-image-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // supercat_header
    var $supercat_header = $('script.inline-template[path="clinique_content_swap/supercat_header"][data-offerkey="' + offer_key + '"]');

    if ($supercat_header.length !== 0) {
      var supercat_header_html = $.parseHTML($supercat_header.html());

      $('.supercat-header-v1[data-offerkey="' + offer_key + '"]').html(supercat_header_html);
    } else {
      $('.supercat-header-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // full_width_promo
    var $full_width_promo = $('script.inline-template[path="clinique_content_swap/full_width_promo"][data-offerkey="' + offer_key + '"]');

    if ($full_width_promo.length !== 0) {
      var full_width_promo_html = $.parseHTML($full_width_promo.html());

      $('.full-width-promo-v1[data-offerkey="' + offer_key + '"]').html(full_width_promo_html);
    } else {
      $('.full-width-promo-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // full_width_2_row
    var $full_width_2_row = $('script.inline-template[path="clinique_content_swap/full_width_2_row"][data-offerkey="' + offer_key + '"]');

    if ($full_width_2_row.length !== 0) {
      var full_width_2_row_html = $.parseHTML($full_width_2_row.html());
      var content = $(full_width_2_row_html).find('.full-width-2-row-v1')[0];
      var target = $('.full-width-2-row-v1[data-offerkey="' + offer_key + '"]');

      target.attr('style', $(content).attr('style'));
      target.attr('data-offerkey', $(content).attr('data-offerkey'));
      if (content) {
        target.html(content.innerHTML);
      }
      Drupal.behaviors.fullWidth2rowV1.attach();
    } else {
      $('.full-width-2-row-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // offer_promo_mobile
    var $offer_promo_mobile = $('script.inline-template[path="clinique_content_swap/offer_promo_mobile"][data-offerkey="' + offer_key + '"]');

    if ($offer_promo_mobile.length !== 0) {
      var offer_promo_mobile_html = $.parseHTML($offer_promo_mobile.html());
      var content = $(offer_promo_mobile_html).find('.offer-promo-mobile-v1')[0];

      $('.offer-promo-mobile-v1[data-offerkey="' + offer_key + '"]').html(content);
    } else {
      $('.offer-promo-mobile-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // offer_layout1_mobile
    var $offer_layout1_mobile = $('script.inline-template[path="clinique_content_swap/offer_layout1_mobile"][data-offerkey="' + offer_key + '"]');

    if ($offer_layout1_mobile.length !== 0) {
      var offer_layout1_mobile_html = $.parseHTML($offer_layout1_mobile.html());

      $('.offer-layout1-mobile-v1[data-offerkey="' + offer_key + '"]').html(offer_layout1_mobile_html);
    } else {
      $('.offer-layout1-mobile-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // basic_image
    var $basic_image = $('script.inline-template[path="clinique_content_swap/basic_image"][data-offerkey="' + offer_key + '"]');

    if ($basic_image.length !== 0) {
      var basic_image_html = $.parseHTML($basic_image.html());

      $('.basic-image-v1[data-offerkey="' + offer_key + '"]').html(basic_image_html);
    } else {
      $('.basic-image-v1[data-offerkey="' + offer_key + '"]').remove();
    }
    // cart_banner
    var $cart_banner = $('script.inline-template[path="clinique_content_swap/cart_banner"][data-offerkey="' + offer_key + '"]');

    if ($cart_banner.length !== 0) {
      var cart_banner_html = $.parseHTML($cart_banner.html());

      $('.cart-banner-v1[data-offerkey="' + offer_key + '"]').html(cart_banner_html);
    } else {
      $('.cart-banner-v1[data-offerkey="' + offer_key + '"]').remove();
    }
  };
  // cookie logic
  var content_cookie = generic.cookie('clinique_special_content');

  $('.clinique_content_swap[data-usecookie]').each(function () {
    var param_name = $(this).attr('data-offerkey');
    var end_date = $(this).attr('data-cookieexpiry');

    if (param_name == content_cookie) {
      // if there's a content cookie set that matches, do the swap
      content_swap(param_name);
    } else if ($.inArray(param_name, Drupal.cl.getQSParams()) > -1) {
      // if there's a query string param that matches, do the swap and set the content cookie
      generic.cookie('clinique_special_content', param_name, {
        expires: new Date(end_date.replace(/-/g, ' ')),
        path: '/'
      });
      // just for backward compatibility now... may end up differentiating later, depending on offer
      // generic.cookie('GETS_SPECIAL_OFFER', param_name,{ expires: new Date(end_date.replace(/-/g, ' ')) });
      content_swap(param_name);
    }
  });

  // check if offers should be swapped when L2 inventory data is received
  $(document).on('inventory_status_stored', function () {
    $('.clinique_content_swap').each(function () {
      var offer_key = $(this).attr('data-offerkey');
      var conjunction = $(this).attr('data-conjunction');
      var oosCount = 0;
      var skuCount = 0;

      $(this).children('.clinique_content_swap_sku').each(function () {
        var sku_id = $(this).attr('data-skuid');
        var sku = $(document).data(sku_id);

        skuCount++;
        // check availability and set offer id. 1 = in stock, 2 = temp out of stock, 6 = available promo stock
        if (sku && ('1,2,6'.indexOf(sku.INVENTORY_STATUS) === -1) && (offer_key !== '')) {
          if (conjunction == 'and') {
            oosCount++;
          } else {
            content_swap(offer_key);

            // if we did the content swap for this sku, we can break out and check the next swap section
            return false;
          }
        }
      });
      if (conjunction == 'and' && oosCount == skuCount) {
        content_swap(offer_key);
      }
    });
  });
})(jQuery);
